import React, { useState, useEffect, useRef } from 'react'
// import ReactDOM from 'react-dom'

import IframeResizer from 'iframe-resizer-react'
// import MessageData from './message-data'

import { connect } from 'react-redux'
import { useIntl, navigate } from 'gatsby-plugin-intl'
import Preloader from './../../../../../s4n/Preloader'

import withDrupalOauthConsumer from './../../../../../drupal-oauth/withDrupalOauthConsumer';
import GeneralUtils from './../../../../../../utils/common'

/**
    https://centarro-commerce-demo.web.app/

    content-security-policy: frame-ancestors 'self' *
    Refused to display 'http://soft4net.test/pl/checkout/157/login' in a frame because it set 'X-Frame-Options' to 'sameorigin'.

    Response Headers (s4n)
        Cache-Control: must-revalidate, no-cache, private
        Cache-Tags: 
        Connection: keep-alive
        Content-language: pl
        Content-Type: text/html; charset=UTF-8
        Date: Mon, 27 Apr 2020 10:43:55 GMT
        Expires: Sun, 19 Nov 1978 05:00:00 GMT
        Location: /pl/checkout/157/login
        Server: nginx/1.17.8
        Transfer-Encoding: chunked
        Vary: 
        X-Content-Type-Options: nosniff
            X-Frame-Options: SAMEORIGIN
        X-Generator: Drupal 8 (https://www.drupal.org)
        X-Powered-By: PHP/7.3.15
        X-UA-Compatible: IE=edge

    Response Headers (https://centarro-commerce-demo.web.app/checkout-iframe/d3502eef-a1ea-48e1-ac0e-b1cb8bdc5f7b)
        cache-control: must-revalidate, no-cache, private
        content-language: en
        content-length: 316
            content-security-policy: frame-ancestors 'self' *
        content-type: text/html; charset=UTF-8
        date: Mon, 27 Apr 2020 10:21:29 GMT
        expires: Sun, 19 Nov 1978 05:00:00 GMT
        location: /checkout/15/login
        status: 302
        strict-transport-security: max-age=0
        vary: Commerce-Current-Store
        x-content-type-options: nosniff
        x-debug-info: eyJyZXRyaWVzIjowfQ==
        x-generator: Drupal 8 (https://www.drupal.org)
        x-platform-cache: BYPASS
        x-platform-cluster: uxgjf2iybi4ag-master-7rqtwti
        x-platform-processor: aqpykgly4zv7hawbmgsztr6cvy
        x-platform-router: hspeymvbhmogco3obtn5o265bq
        x-request-id: rtspqgd3cqia7alyybwjbqb6
        x-ua-compatible: IE=edge

    Query String Parameters (shared)
        cartToken: i12w4gfdb3
*/

const CHECKOUT_IFRAME_ID = `checkout-iframe`;

const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const {
        dispatch,
        cart: { cartToken, carts, itemCount },
        cartFlyout: { open }
    } = props;

// console.log(cart) // === undefined, it seems this is how destructing works in nested arrays


    const [iframeContentLoaded, setIframeContentLoaded] = useState(false);

// console.log(props.cart)
// console.log(props.cartFlyout)



	// Returns default cart, Drupal Commerce allow to have multiple carts
	const getCart = () => {
		const { cart: { carts } } = props;
		const defaultCart = carts[0];

		if (typeof defaultCart === `undefined`) {
			const cart = {
				order_items: [],
			}
			return cart;
		}

		return defaultCart;
	}

	const cart = getCart();



// console.log(carts)
// console.log(cart)
// console.log(props.cartFlyout)




    // const isUserAuthenticated = this.props.userAuthenticated;
    // console.log(isUserAuthenticated);
    const token = props.drupalOauthClient.isLoggedIn();
    // console.log(token);

    let pathUser = `${process.env.REACT_APP_API_URL}/${intl.locale}/user/772`;
    let authorizationParams = ``;
    if (token) {
        // authorizationParams = `?authorizationTokenType=${token.token_type}&authorizationTokenAccessToken=${token.access_token}`;
        pathUser += authorizationParams;
    }

// pathUser = false;

    



    const isBrowser = typeof window !== 'undefined';


    const iframeLoaded = (e) => {
        // const iframe = document.getElementById(CHECKOUT_IFRAME_ID);
        // if (iframe) {
        //     iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
        // }

        const iframe = document.getElementById(CHECKOUT_IFRAME_ID);     
        
// console.log(iframe)
        
        if (!iframe) {
            return false;
        }

        iframe.onload = function() {
            iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
        }
    }

    // @see: https://github.com/davidjbradshaw/iframe-resizer-react/tree/master/example/src
    const iframeRefCheckout = useRef(null)
    const iframeRefUser = useRef(null)
    // const [messageData, setMessageData] = useState()
    // const onResized = data => setMessageData(data)
    // const onMessage = data => {
    //   setMessageData(data)
    //   iframeRef.current.sendMessage('Hello back from the parent page')
    // }



    if (itemCount === 0) {
        // return null;
    }



/*
    // @see: https://stackoverflow.com/questions/13432821/is-it-possible-to-add-request-headers-to-an-iframe-src-request
    var xhr = new XMLHttpRequest();

    xhr.open('GET', `${process.env.REACT_APP_API_URL}/${intl.locale}/checkout/${cart.order_id}?cartToken=${cartToken}`);
    xhr.onreadystatechange = handler;
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.send();
    
    function handler() {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          // this.response is a Blob, because we set responseType above
          var data_url = URL.createObjectURL(this.response);
          document.querySelector(`#${CHECKOUT_IFRAME_ID}`).src = data_url;
        } else {
          console.error('no pdf :(');
        }
      }
    }
*/






/*
var html = document.querySelector('#html');
var pdf = document.querySelector('#pdf');

populateIframe(html, 'other.html', [['x-api-version', 'v1.2']]);
populateIframe(pdf, 'peepcode-git.pdf', [['Authorization', 'Bearer abcd1234']]);
*/

// @see: https://github.com/courajs/pdf-poc
function populateIframe(iframe, url, headers) {
  var xhr = new XMLHttpRequest();

  xhr.open('GET', url);
  xhr.onreadystatechange = handler;
  xhr.responseType = 'blob';
  headers.forEach(function(header) {
    xhr.setRequestHeader(header[0], header[1]);
  });
  xhr.send();

  function handler() {
    if (this.readyState === this.DONE) {
      if (this.status === 200) {
        // this.response is a Blob, because we set responseType above
        iframe.src = URL.createObjectURL(this.response);
      } else {
        console.error('XHR failed', this);
      }
    }
  }


}

function setPDF(blob) {
  document.querySelector('#blob').src = URL.createObjectURL(blob);
}








    // console.log(carts)








    const cartUuid = props.checkoutId ? props.checkoutId : cart.id;
    // const cartId = cart.attributes.drupal_internal__order_id;

    let checkoutId = ``;
    if (cartUuid) {
        checkoutId = cartUuid;
    } else {
        const orderId = props.orderId ? props.orderId : GeneralUtils.findGetParameter(`orderId`);
        if (orderId) {
            checkoutId = `${orderId}/payment/return`;
        }
    }

    // if (!cartId) {
    //     navigate(`/cart`)
    //     return null;
    // }





    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
        // if (!isBrowser) {
        //     return false
        // }

        // componentDidMount();
    }, []);

    const componentDidMount = () => {
        // iframeLoaded();

        const iframeRefUser = document.getElementById(CHECKOUT_IFRAME_ID);
        // console.log(iframeRefUser)

        if (iframeRefUser && pathUser) {
            populateIframe(iframeRefUser, pathUser, [
            // populateIframe(iframeRefUser.current, pathUser, [
                ['Authorization', `${token.token_type} ${token.access_token}`], 
                // ['Access-Control-Allow-Origin', '*']
            ]);
        }

    }

    // if (!iframeContentLoaded) {
    //     return <Preloader />
    // }

    let pathCheckout = `${process.env.REACT_APP_API_URL}/${props.locale ? props.locale : intl.locale}/checkout/${checkoutId}?cartToken=${cartToken}`;
    if (false && token) {
        // authorizationParams = `&authorizationTokenType=${token.token_type}&authorizationTokenAccessToken=${token.access_token}`;
        authorizationParams = `&access_token=${token.access_token}`;
        pathCheckout += authorizationParams;
    }

    return (
        <React.Fragment>

            {!iframeContentLoaded && <Preloader />}

            {/* <h1>{intl.locale} | {props.locale} | {props.checkoutId}</h1> */}

            {true && checkoutId && 
                <IframeResizer
                    // log
                    id={CHECKOUT_IFRAME_ID}
                    forwardRef={iframeRefCheckout}
                    title={`Embedded checkout`}
                    inPageLinks
                    // onMessage={onMessage}
                    // onResized={onResized}
                    // onLoad={(e) => {
                    //     const iframe = document.getElementById(CHECKOUT_IFRAME_ID);     
                    //     if (!iframe) {
                    //         return false;
                    //     }
                    //     // const iframe = e.target;
                    //     iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
                    // }}
                    // onLoad={(e) => iframeLoaded(e)}
                    // onload="resizeIframe(this)"
                    // onLoad={() => {
                    //     const obj = ReactDOM.findDOMNode(this);

                    //     setState({
                    //         iFrameHeight: obj.contentWindow.document.body.scrollHeight + 'px'
                    //     });
                    // }} 

                    onResized={({iframe, height, width, type}) => {
                        // alert('onResized')
                        setIframeContentLoaded(true);
                    }}

                    src={pathCheckout} 
                    style={{width: `1px`, minWidth: `100%`}}
                    scrolling="no"
                    frameBorder="0"

                    checkOrigin={false}
                    // checkOrigin={[
                    //     // 'http://localhost',
                    //     // 'http://localhost:8000',
                    //     // 'http://127.0.0.1:8000',
                    //     process.env.SOFT4NET_SOURCE_SHOP_URL,
                    // ]}

                    // heightCalculationMethod="bodyOffset"
                    heightCalculationMethod="lowestElement"
                />
            }



            {/* User account */}
            {/* 'Authorization': `${token.token_type} ${token.access_token}` */}
            {false && pathUser &&
                <IframeResizer
                    // log
                    id={CHECKOUT_IFRAME_ID}
                    forwardRef={iframeRefUser}
                    title={`User account`}
                    inPageLinks
                    
                    // onInit={(e) => {
                    //     populateIframe(iframeRefUser.current, pathUser, [
                    //         ['Authorization', `${token.token_type} ${token.access_token}`]
                    //         // ['Access-Control-Allow-Origin', '*']
                    //     ]);
                    // }}
                    // onLoad={(e) => {
                    //     populateIframe(iframeRefUser.current, pathUser, [
                    //         ['Authorization', `${token.token_type} ${token.access_token}`],
                    //         ['Access-Control-Allow-Origin', '*']
                    //     ]);
                    // }}

                    // onMessage={onMessage}
                    // onResized={onResized}

                    onResized={({iframe, height, width, type}) => {
                        // alert('onResized')
                        setIframeContentLoaded(true);
                    }}

                    // src={pathUser} 
                    // src={``} 

                    style={{width: `1px`, minWidth: `100%`}}
                    scrolling="no"
                    frameBorder="0"
                    
                    checkOrigin={false}
                    // checkOrigin={[
                    //     // 'http://localhost',
                    //     // 'http://localhost:8000',
                    //     // 'http://127.0.0.1:8000',
                    //     process.env.SOFT4NET_SOURCE_SHOP_URL,
                    // ]}

                    // heightCalculationMethod="bodyOffset"
                    // heightCalculationMethod="lowestElement"
                />            
            }


            {/* <iframe id={CHECKOUT_IFRAME_ID} src={``}></iframe> */}
            {/* {pathUser && populateIframe(iframe, pathUser, [['Authorization', `${token.token_type} ${token.access_token}`]])} */}



            {/* <MessageData data={messageData} /> */}
            {/* <iframe 
                id={CHECKOUT_IFRAME_ID}
                
                // onLoad={(e) => {
                //     const iframe = document.getElementById(CHECKOUT_IFRAME_ID);     
                //     if (!iframe) {
                //         return false;
                //     }
                //     // const iframe = e.target;
                //     iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
                // }}
                // onLoad={(e) => iframeLoaded(e)}
                // onload="resizeIframe(this)"
                // onLoad={() => {
                //     const obj = ReactDOM.findDOMNode(this);

                //     setState({
                //         iFrameHeight: obj.contentWindow.document.body.scrollHeight + 'px'
                //     });
                // }} 

                title={`Embedded checkout`}
                // ref="iframe"
                // src="https://api.api-demo.centarro.io/checkout/d3502eef-a1ea-48e1-ac0e-b1cb8bdc5f7b?cartToken=i12w4gfdb3" 
                // src={`${process.env.REACT_APP_API_URL}/${intl.locale}/checkout/${cart.order_id}?cartToken=${cartToken}&s4n_url_return=${s4n_url_return}`} 
                src={`${process.env.REACT_APP_API_URL}/${intl.locale}/checkout/${cart.order_id}?cartToken=${cartToken}`} 

                style={{
                    overflow: `visible`,
                    width: `100%`, 
                    // height: `100vh`,
                    height: state.iFrameHeight,
                }}
                width="100%" 
                height={state.iFrameHeight} 
                scrolling="no" 
                frameBorder="0"
            ></iframe> */}
        </React.Fragment>
    )
}

// const mapStateToProps = ({ cart, cartFlyout }) => ({ cart, cartFlyout });
const mapStateToProps = state => {
    return {
      cart:       state.shopReducers.cart,
      cartFlyout: state.shopReducers.cartFlyout,
    }
};

export default connect(mapStateToProps)(withDrupalOauthConsumer(Component));