// @see: https://www.gatsbyjs.com/docs/client-only-routes-and-user-authentication/
// @see: https://gatsbyguides.com/tutorial/create-client-only-routes
// @see: https://www.gatsbyjs.org/docs/client-only-routes-and-user-authentication/
// @see: https://github.com/wiziple/gatsby-plugin-intl/issues/68
// @see: https://github.com/wiziple/gatsby-plugin-intl/issues/93

import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/layout';

// s4n
import ShopCheckoutIframe from './../components/s4n/Shop/s4n/Checkout/Iframe';

import PrivateRoute from '../components/s4n/AccountManagement/PrivateRoute';
import Checkout from '../components/s4n/Shop/ext/pages/Checkout';
import PaymentConfirmed from '../components/s4n/Shop/ext/pages/PaymentConfirmed';
// import CheckoutEmbed from '../components/s4n/Shop/ext/pages/CheckoutEmbed';

const CheckoutRoute = () => {
  return (
    <Layout>
      {/* Drupal Commerce iframe checkout START */}

      {/* {`null` !== process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID && `iframe` === process.env.SOFT4NET_SHOP_CHECKOUT_TYPE && 
        <ShopCheckoutIframe />
      } */}

      <Router>
        {/* http://decolly.soft4net.io/pl/checkout/c29bb971-5799-473b-8015-c0a718deac04 */}
        <ShopCheckoutIframe path="/:locale/check-out/:checkoutId" />

        {/* http://decolly.soft4net.io/pl/checkout/267/payment/return?orderId=267 */}
        {/* <ShopCheckoutIframe path="/:locale/checkout/:orderId/payment/return" /> */}
      </Router>

      {/* Drupal Commerce iframe checkout STOP */}

      {/* Commerce API checkout START */}
      {false && 
        <Router
          basepath="/:locale/checkout" // If we provide basepath we do need prtovide full path in each route just last part, it will concatenate automatically!!!
        >
  {/* 
          <Route path={`/checkout/:orderId/complete`} component={PaymentConfirmed} />
          <Route path={`/checkout/:orderId`} component={Checkout} />
          <Route path={`/checkout-iframe/:orderId`} component={CheckoutEmbed} />
  */}
          <Checkout path={`/:orderId`} />
          {/* <CheckoutEmbed path={`/:locale/checkout-iframe/:orderId`} /> */}
          <PaymentConfirmed path={`/:orderId/complete`} />

          {/* <Default path="/" /> */}

        </Router>
      }
      {/* Commerce API checkout STOP */}

    </Layout>
  )
}

export default CheckoutRoute;